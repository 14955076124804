import { useState, useRef } from "react"
import api from "../utils/api"

function UserPanel(props) {
  const [selectedUser, setSelectedUser] = useState({ member: null, fields: [], votes: [] })
  const details = useRef(null)

  const getInfo = async (ignoreDetails = false) => {
    if ((!details.current.open || ignoreDetails)) {
      api.get(`/members/info/${props.member.user.id}`).then(res => setSelectedUser(res.data))
    }
  }

  const handleDetails = (e) => {
    if (e.target.open) {
      document.querySelector(".main").classList.remove("disable-scroll")
    } else {
      document.querySelector(".main").classList.add("disable-scroll")
    }

    if (e.target.classList.contains("member-item")) {
      if (e.target.open) e.target.open = false
    }
  }

  return (
    <>
      <details className="member-item" ref={details} onClick={(e) => handleDetails(e)}>
        <summary onClick={getInfo}>
          <div className="summary-header">
            <img src={props.member.user.displayAvatarURL} alt="User avatar" width={42} />
            <span style={{ color: props.member.user.displayHexColor === "#0" ? '#ddd' : props.member.user.displayHexColor }} id="user-title">
              {props.member.user.displayName}
              {
                props.member.displayHexColor === "#896be6"
                  ?
                  <span style={{ marginLeft: '4px' }}>🐂</span>
                  :
                  null
              }
            </span>
          </div>
        </summary>
        <section className="drawer">
          {selectedUser.member !== null
            ?
            <div className="profile-details">
              <div className="profile-header d-flex align-items-center justify-content-between">
                <div>
                  <img src={selectedUser.member?.displayAvatarURL} alt="User avatar" />
                  <h4 style={{ color: props.member.user.displayHexColor }}>
                    {selectedUser.member?.displayName}
                  </h4>
                </div>
              </div>
              <div className="roles">
                {selectedUser.member?.roles?.map((role, index) => {
                  if (role.color === "#0") {
                    return (<span key={index} className="role-item" style={{ backgroundColor: '#cacaca' }}>{role.name}</span>)
                  } else {
                    return (<span key={index} className="role-item" style={{ backgroundColor: role.color }}>{role.name}</span>)
                  }
                })}
              </div>
              {selectedUser.fields?.map((field, index) => (
                <div key={index} style={{ opacity: '0.8' }}>
                  {field.name}: <span>{field.value}</span>
                </div>
              ))}
            </div>
            :
            <div className="spinner-border" style={{ display: 'block', opacity: '0.8', margin: '30px auto' }}>
              <span className="visually-hidden">Carregando...</span>
            </div>
          }

          {props.member.votes > 0 && selectedUser.votes.length > 0
            ?
            <div className="ul votes-list">
              <br />

            </div>
            :
            null
          }
        </section>
      </details>
    </>
  )
}


export default UserPanel