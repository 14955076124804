import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { UserProvider } from './contexts/UserContext'

import { Auth } from './containers/Auth'
import Menu from './components/Menu'
import Home from './containers/Home'
import Mensagem from './containers/Mensagem'

import session from './utils/session'

function App() {

  return (
    <BrowserRouter>
      <UserProvider>
        <div className="app">
            <div className="main">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/auth" element={<Auth />} />
                {session.isAdmin()
                  ?
                  <>
                    <Route path="/mensagem" element={<Mensagem />} />
                  </>
                  :
                  null
                }
              </Routes>
            </div>
            <Menu />
        </div>
      </UserProvider>
    </BrowserRouter>
  )
}

export default App