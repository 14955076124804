import CryptoJS from "crypto-js"

class Session {
  userStorage = localStorage.getItem("hellbot.me")
  key = process.env.REACT_APP_DKEY

  isSessionExpired() {
    const user = this.getUser()
    const now = new Date().getTime()

    return now > (new Date(user?.expiresIn).getTime())
  }

  isAuthenticated() {
    if (this.userStorage != null) {
      if (this.isSessionExpired()) {
        this.logout()
        return false
      }

      return Boolean(this.getUser()?.user?.id)
    }
    else return false
  }

  isAdmin() {
    const user = this.getUser()
    try { 
      return user.user.id === process.env.REACT_APP_OWNER
    } catch(err) {
      return false
    }
  }

  getUser() {
    if (localStorage.getItem('hellbot.me') !== null) {
      const encrypted = localStorage.getItem('hellbot.me')
      const bytes = CryptoJS.AES.decrypt(encrypted, this.key)

      try {
        let originalText = bytes.toString(CryptoJS.enc.Utf8)
        const data = JSON.parse(originalText)
        return data
      } catch (e) {
        this.logout()
      }
    }
    else if (this.isAuthenticated()) {
      this.logout()
    }
    return null
  }

  getToken() {
    if (localStorage.getItem('hellbot.me') !== null) {
      return localStorage.getItem('hellbot.me')
    }

    return null
  }

  logout() {
    localStorage.removeItem("hellbot.me")
    setTimeout(() => {
      window.location.reload()
    }, 1000)
  }
}

const session = new Session()
export default session