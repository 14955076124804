import { useEffect, useState } from "react"
import UserPanel from "../components/UserPanel"
import api from "../utils/api"

function Home() {
  const [memberData, setMemberData] = useState({ members: null, atual: null })
  const [filtered] = useState([])
  const [lastVoted, setLastVoted] = useState(null)

  const getMemberData = () => {
    api.get("/members").then((res) => {
      if (res?.data) setMemberData(res?.data)
    })
  }

  useEffect(() => { getMemberData() }, [])

  const renderMembers = () => {
    let list = []
    list = filtered.length > 0 ? filtered : memberData?.members

    return list?.map((member, index) => (
      <UserPanel
        key={index}
        lastVoted={lastVoted}
        member={member}
        getMemberData={getMemberData}
        setLastVoted={(m) => setLastVoted(m)}
      />
    ))
  }

  return (
    <section className="container">
      <h3 className="title d-flex align-items-center">Home</h3>
      {
        memberData?.members === null
          ?
          <div
            className="spinner-border text-light"
            role="status"
            style={{ display: 'block', margin: '18vh auto', opacity: '0.8' }}>
            <span className="visually-hidden">Carregando...</span>
          </div>
          :
          <div className="members">
            {renderMembers()}
          </div>
      }
    </section>
  )
}

export default Home