import { Modal, Popover } from "@mui/material"
import { useContext, useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom"
import UserContext from "../contexts/UserContext"
import session from "../utils/session"
import api from "../utils/api"

function Navbar() {
  const { state: user } = useContext(UserContext)
  const menuRef = useRef(null)
  const [userPopover, setUserPopover] = useState(null)
  const [warningModal, setWarningModal] = useState(false)
  const [path, setPath] = useState("/")
  const noNav = ["/auth"]

  useEffect(() => setPath(window.location.pathname), [path])

  function handlePath(mobile = false) {
    setTimeout(() => setPath(window.location.pathname), 100)
    if (userPopover !== null) setUserPopover(null)

    if (mobile) {
      toggleMenu()
    }
  }

  function toggleMenu() {
    const mobileMenu = document.querySelector(".hell-menu-mobile")
    const menuToggle = document.querySelector("#mobile-menu-toggle i")

    if (mobileMenu.style.display === "flex") {
      mobileMenu.style.display = "none"
      menuToggle.innerHTML = "menu"
    } else {
      mobileMenu.style.display = "flex"
      menuToggle.innerHTML = "close"
    }
  }

  return !noNav.includes(window.location.pathname) ? (
    <>
      <Modal open={warningModal} onClose={() => setWarningModal(false)}>
        <div className="hell-modal">
          <h6>Tem certeza que deseja ser redirecionado ao servidor Discord?</h6>
          <div className="d-flex justify-content-end mt-4 mb-2">
            <button
              onClick={() => setWarningModal(false)}
              style={{ marginRight: '10px', background: '#383838' }}>
              Cancelar
            </button>
            <a
              href="https://discord.gg/FMGafx8C4x"
              target="_blank"
              rel="noreferrer"
              onClick={() => setWarningModal(false)}>
              <button>Sim</button>
            </a>
          </div>
        </div>
      </Modal>

      <div className="hell-menu hide-sm">
        <nav>
          <Link
            to="/"
            onClick={handlePath}
            className={path === "/" ? "active" : ""}>
            <i className="material-icons">home</i>
            Home
          </Link>

          {session.isAdmin()
            ?
            <Link
              to="/mensagem"
              onClick={handlePath}
              className={path === "/mensagem" ? "active" : ""}>
              <i className="material-icons">message</i>
              Mensagem
            </Link>
            :
            null
          }

          <button onClick={() => setWarningModal(true)}>
            <i className="material-icons">dns</i>
            Servidor
          </button>

          {session.isAuthenticated()
            ?
            <div className="user-float" onClick={(e) => setUserPopover(e.currentTarget)} >
              <span className="user-info">
                {user?.avatar
                  ?
                  <img src={`https://cdn.discordapp.com/avatars/${user?.id}/${user?.avatar}.png`} alt="User avatar" />
                  :
                  <div className="user-header" style={{ backgroundColor: user?.banner_color }}></div>
                }
              </span>
            </div>
            :
            <a role="button" href={api.redirectUrl} id="login">Login</a>
          }
        </nav>
      </div>

      <div className="hell-menu-mobile hide-md" ref={menuRef}>
        <li>
          <Link
            to="/"
            onClick={() => handlePath(true)}
            className={path === "/" ? "active" : ""}>
            <i className="material-icons">home</i>
            Home
          </Link>
        </li>

        <li onClick={() => { setWarningModal(true); toggleMenu() }} className="hide-md">
          <button>
            <i className="material-icons">dns</i>
            Servidor
          </button>
        </li>

        {session.isAuthenticated()
          ?
          <div className="user-float">
            <span className="user-info" onClick={(e) => setUserPopover(e.currentTarget)}>
              {user?.avatar
                ?
                <img src={`https://cdn.discordapp.com/avatars/${user?.id}/${user?.avatar}.png`} alt="User avatar" />
                :
                <div className="user-header" style={{ backgroundColor: user?.banner_color }}></div>
              }
            </span>
          </div>
          :
          <a role="button" href={api.redirectUrl} id="login">Login</a>
        }
      </div>

      <button id="mobile-menu-toggle" className="hide-md" onClick={toggleMenu}>
        <i className="material-icons">menu</i>
      </button>

      <Popover
        open={Boolean(userPopover)}
        anchorEl={userPopover}
        onClose={() => setUserPopover(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'left', }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left', }}
      >
        <div id="hell-popover-content">
          <div className="d-flex flex-column px-2">
            <span style={{ opacity: '0.6', fontSize: '14px', marginTop: '6px' }}>
              {user?.username}
            </span>
          </div>

          <li onClick={() => session.logout()}>
            <i className="material-icons">exit_to_app</i>
            Sair
          </li>
        </div>
      </Popover>
    </>
  ) : null
}

export default Navbar
