import { useState } from "react"
import api from "../utils/api"

export default function Mensagem() {
    const [channelOptions, setChannelOptions] = useState(null)
    const [toast, setToast] = useState({ active: false, text: "" })
    const [enabled, setEnabled] = useState(true)
    const [form, setForm] = useState({
        channel: null,
        embed: false,
        description: "",
        title: "",
        color: "#7465e6",
    })

    const getChannels = () => {
        api.get("/guild/channels").then(res => {
            const channels = res?.data
            setChannelOptions(channels)
            setForm({ ...form, channel: channels?.[0]?.id })
        })
    }

    if (channelOptions === null) {
        getChannels()
    }
    
    const handleForm = (e) => {
        if (e.target.type === "checkbox") {
            setForm({ ...form, [e.target.name]: e.target.checked })
        } else {
            setForm({ ...form, [e.target.name]: e.target.value })
        }
    }

    const submit = async () => {
        setEnabled(false)

        if (form.embed) {
            if (!form.channel || !form.title || !form.description) {
                setToast({ active: true, text: "Preencha todos os campos obrigatórios!" })
                setTimeout(() => setToast({ active: false, text: "" }), 5000)
                return
            }
        } else {
            if (!form.description) {
                setToast({ active: true, text: "Preencha todos os campos obrigatórios!" })
                setTimeout(() => setToast({ active: false, text: "" }), 5000)
                return
            }
        }

        await api.post("/guild/messages", form)
        setTimeout(() => setEnabled(true), 5000)
    }

    const clear = () => {
        setForm({
            ...form,
            ...{
                description: "",
                title: "",
            }
        })
    }

    return (
        <div className="container">
            {toast.active
                ?
                <div className="hell-toast"><span>{toast.text}</span></div>
                :
                null
            }
            <h3 className="title d-flex">Mensagem</h3>
            <label className="label">
                Canal<br />
                <select
                    onChange={(e) => setForm({ ...form, channel: e.target.value })}
                    style={{ width: '260px', maxWidth: '100%' }}>
                    {channelOptions?.map((option, index) => (
                        <option value={option.id} key={index}>{option.name}</option>
                    ))}
                </select>
            </label>
            <br />
            <label className="py-3">
                <input type="checkbox" checked={form.embed} name="embed" onChange={handleForm} />
                Embed?
            </label>
            <hr style={{ margin: '20px 0' }} />
            <div className="row">
                <div className="form col-md-6">
                    <br />
                    {form.embed ?
                        <label>
                            Título
                            <input type="text" name="title" value={form.title} onChange={handleForm} />
                        </label>
                        :
                        null
                    }
                    <label>
                        Mensagem
                        <input type="text" name="description" value={form.description} onChange={handleForm} />
                    </label>
                    {form.embed ?
                        <label>
                            Cor
                            <input type="color" name="color" value={form.color} onChange={handleForm} disabled={!form.embed} />
                        </label>
                        :
                        null
                    }
                </div>
                <div className="col-md-6">
                    {/* <h5>Preview</h5> */}
                </div>
            </div>
            <button className="mt-3" onClick={submit} disabled={!enabled}>
                Enviar
            </button>
            {' '}
            <button className="mt-3 bg-secondary" onClick={clear}>
                Limpar
            </button>
        </div>
    )
}