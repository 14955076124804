import { useEffect } from 'react'
import CryptoJS from 'crypto-js'
import waves from '../assets/waves.svg'
import api from '../utils/api'
import Spinner from '../components/Spinner'

function Auth() {
  const authenticate = async () => {
    const fragment = new URLSearchParams(window.location.hash.slice(1))
    const [accessToken, tokenType] = [fragment.get('access_token'), fragment.get('token_type')]

    if (accessToken && tokenType) {
      const token = `${tokenType} ${accessToken}`

      await fetch('https://discord.com/api/users/@me', { headers: { authorization: token } })
        .then(result => result.json())
        .then(response => {
          let date = new Date()
          date.setDate(date.getDate() + 30)
          const encrypted = CryptoJS.AES.encrypt(JSON.stringify({ user: response, expiresIn: date }), process.env.REACT_APP_DKEY).toString()
          localStorage.setItem("hellbot.me", encrypted)
        })
        .catch((err) => console.error(err))
    }

    window.location.href = "/"
    setTimeout(() => window.location.reload(), 250)
  }

  useEffect(() => {
    authenticate()
  }, [])

  return <Spinner />
}

function AuthScreen() {
  return (
    <div className="auth-required">
      <div id="background">
        <embed src={waves} alt="background" />
      </div>
      <div className="prompt">
        <h5>Faça login para prosseguir</h5>
        <br />
        <a role="button" href={api.redirectUrl} id="login">Login</a>
      </div>
    </div>
  )
}

export { Auth, AuthScreen }