import React, { createContext, useEffect, useState } from "react"
import session from "../utils/session"

const DEFAULT_VALUE = {
  state: null,
  setState: () => { }
}

const UserContext = createContext(DEFAULT_VALUE)

const UserProvider = ({ children }) => {
  const [state, setState] = useState(DEFAULT_VALUE.state)

  useEffect(() => {
    if (session.isAuthenticated())
      setState(session.getUser()?.user)
  }, [])

  return (
    <UserContext.Provider value={{ state, setState }}>
      {children}
    </UserContext.Provider>
  )
}

export { UserProvider }
export default UserContext