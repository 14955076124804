import axios from "axios"
import session from "./session"

class Api {
  redirectUrl = null
  // address = process.env.NODE_ENV === "development" ? "http://localhost:2000" : "https://hellbot-a7as.onrender.com"
  address = "https://hellbot-a7as.onrender.com"

  constructor() {
    const redirectUrl = encodeURIComponent(process.env.NODE_ENV === "production" ? "https://hellbot.pages.dev/auth" : `${window.location.origin}/auth`)
    this.redirectUrl = `https://discord.com/api/oauth2/authorize?client_id=901598143861358653&redirect_uri=${redirectUrl}&response_type=token&scope=identify`
  }

  post(url, data) {
    let config = {
      method: "POST",
      url: this.address + url,
      data: data
    }

    if (session.isAuthenticated()) {
      config["headers"] = { "Authorization": `Bearer ${session.getToken()}` }
    }

    return axios(config)
  }

  get(url) {
    let config = {
      method: "GET",
      url: this.address + url
    }

    if (session.isAuthenticated()) {
      config["headers"] = { "Authorization": `Bearer ${session.getToken()}` }
    }

    return axios(config)
  }

  delete(url) {
    let config = {
      method: "DELETE",
      url: this.address + url
    }

    if (session.isAuthenticated()) {
      config["headers"] = { "Authorization": `Bearer ${session.getToken()}` }
    }

    return axios(config)
  }
}

const api = new Api()
export default api